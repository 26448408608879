
import { defineComponent, ref } from "vue";
import useModel from "@/hooks/useModel";
export default defineComponent({
  name: "App",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    activeKey: {
      type: String
    }
  },
  setup(props, context) {
    const _activeKey = useModel(props, "activeKey");
    const handleTabChange = (key: string) => {
      context.emit("on-tab-change", key);
    };
    return { _activeKey, handleTabChange };
  },
});
