
import { defineComponent, ref, reactive, watch } from "vue";
import useModel from "@/hooks/useModel";
import { getPayoutCodeStatus } from "@/API/payment";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const column = reactive([
      {
        title: "Payout Round",
        dataIndex: "payoutRound",
      },
      {
        title: "Payout Code",
        dataIndex: "payoutCode",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
    ]);
    const _visible = ref(false);
    const handleClose = () => {
      _visible.value = false;
    };
    const payoutRound = ref();
    const data = ref();
    const getTableData = (round: string, entity: string) => {
      data.value = [];
      payoutRound.value = round;
      getPayoutCodeStatus({
        entity: entity,
        payoutRound: round,
        type: "DEALER",
      }).then((res) => {
        data.value = res;
        _visible.value = true;
      });
    };
    return {
      _visible,
      payoutRound,
      handleClose,
      getTableData,
      data,
      column,
    };
  },
});
