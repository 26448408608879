import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58a81d4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "set-wrapper" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    title: "Set",
    placement: "right",
    visible: _ctx._visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._visible) = $event)),
    width: "400"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_table, {
          dataSource: _ctx.data,
          columns: _ctx.column,
          rowKey: "code",
          pagination: false
        }, {
          set: _withCtx(({ record }) => [
            _createElementVNode("span", {
              class: "link",
              onClick: ($event: any) => (_ctx.handleSet(record))
            }, _toDisplayString(record.value), 9, _hoisted_2)
          ]),
          _: 1
        }, 8, ["dataSource", "columns"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}