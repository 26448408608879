
import { defineComponent, reactive, ref, unref, watch } from "vue";
import * as API from "@/API/payment";
import useModel from "@/hooks/useModel";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { message } from "ant-design-vue";
import downloadFile from "@/utils/payment/downloadFile";
type DownloadParams = {
  payoutRound: string | undefined;
  entity: string | undefined;
};
type Key = ColumnProps["key"];
export interface SalesTableData {
  id: string;
  payoutRound: string;
  payoutCode: string;
  payoutAmountWVat: string;
  payoutAmountWOVat: string;
  updatedAt: string;
  status: string;
}
export default defineComponent({
  name: "App",
  props: {},
  setup(props) {
    const _visible = ref(false);

    const entity = ref();
    const payoutRound = ref();
    let signedA3DownloadDisabled = false;

    const modalColumnsData = [{ title: "File Name", dataIndex: "value" }];
    const modalTableData = ref();
    const modalRowSelection = reactive<{
      selectedRowKeys: Key[];
      onChange: Function;
      columnWidth: string;
      getCheckboxProps: (...args: any[]) => any;
    }>({
      onChange: (selectedRowKeys: Key[]) => {
        modalRowSelection.selectedRowKeys = selectedRowKeys;
      },
      selectedRowKeys: [],
      columnWidth: "20px",
      getCheckboxProps: (record: any) => ({
        disabled: record.value === "Signed A3" && signedA3DownloadDisabled,
      }),
    });
    const getTableData = (_round: string, _entity: string) => {
      modalTableData.value = [];
      modalRowSelection.selectedRowKeys = [];
      payoutRound.value = _round;
      entity.value = _entity;
      API.summaryLogCheck(payoutRound.value, entity.value)
        .then((data) => {
          signedA3DownloadDisabled = data === "0";
        })
        .then(() => {
          API.getDownloadFileList(entity.value).then((data) => {
            modalTableData.value = data;
            _visible.value = true;
          });
        });
    };
    const modalDownloadClick = () => {
      if (modalRowSelection.selectedRowKeys.length === 0) {
        message.warning("Please select the data first");
        return;
      }
      const params = {
        url:
          "/ptapi/export/sales/downloadFiles?payoutRound=" +
          payoutRound.value +
          "&entity=" +
          entity.value,
        method: "post",
        params: modalRowSelection.selectedRowKeys,
      };
      downloadFile(params);
    };
    const downloadClose = () => {
      _visible.value = false;
    };
    return {
      _visible,
      entity,
      payoutRound,
      modalColumnsData,
      modalTableData,
      modalRowSelection,
      getTableData,
      modalDownloadClick,
      downloadClose,
    };
  },
});
