
import { defineComponent, ref, computed, onMounted } from "vue";
import { FileItem } from "@/API/types";
import { message } from "ant-design-vue";
import * as API from "@/API/payment";
import { Modal } from "ant-design-vue";
import { FileInfo } from "@/API/types";
import { SalesTableData } from "@/hooks/payment/EntitySiPayout/useSalesPayment";
import { useStore } from "vuex";
const attachmentColumns = [
  {
    title: "Attachment",
    dataIndex: "fileName",
    width: 200,
    slots: { customRender: "comments" },
  },
  { title: "Upload Date", dataIndex: "updatedAt", width: 110 },
  {
    title: "Operation",
    key: "operation",
    width: 165,
    slots: { customRender: "action" },
  },
];

export default defineComponent({
  emits: ["close-upload-visible"],
  props: {
    isUploadVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    round: {
      type: String,
      default: "",
    },
    entity: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const { state } = useStore();

    const payoutRound = computed(() => {
      return props.round;
    });
    const myAttachmentData = ref<null | unknown>(null);
    const getFileList = () => {
      API.getFileList(
        payoutRound.value,
        `upload_sales_file_${props.entity}`
      ).then((res) => {
        myAttachmentData.value = res;
      });
    };
    getFileList();
    // API.getDealerNewRound().then(data => {
    //   payoutRound = data.payoutRound
    //   getFileList()
    // })
    onMounted(() => {
      //rrrr
    });
    // 文件上传
    const uploading = ref<boolean>(false);
    const isLt5M = ref<boolean[]>([]);
    const uploadComplete = ref<boolean>(true);
    // 获取标题内容
    const title = computed(() => `Upload Attachment File`);
    // // 设置抽屉显示状态
    // const modal = (props as any).isUploadVisible;
    const close = () => {
      context.emit("close-upload-visible", { isUploadVisible: false });
    };

    const fileList = ref<FileItem[]>([]);
    // 上传前
    const beforeUpload = (file: FileItem) => {
      console.log("进入上传前方法中。。。。。。。");
      // const isHtml = file.type === 'text/html';
      // if (isHtml) {
      //   message.error('不能上传html格式文件!')
      // }
      isLt5M.value.push(file.size / 1024 / 1024 < state.payment.maxFileSize);
      /*if (file.size / 1024 / 1024 > 5) {
        message.error('图片不得大于5MB!')
      }*/
      return false;
    };

    const fileChange = (info: FileInfo) => {
      const lastFile: FileItem = info.fileList[info.fileList.length - 1];
      if (lastFile && lastFile.type === "text/html") {
        message.error("Attachment can not be an HTML file.");
        fileList.value.splice(info.fileList.length - 1, 1);
        isLt5M.value.splice(info.fileList.length - 1, 1);
      }
    };
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
      isLt5M.value = [];
      //console.log('isLt5m===============',isLt5M)
      fileList.value.forEach((item: FileItem) => {
        isLt5M.value.push(item.size / 1024 / 1024 < state.payment.maxFileSize);
      });
    };
    const submitFile = () => {
      console.log("上传的附件个数为：", fileList.value.length);
      const formData = new FormData();
      if (fileList.value.length <= 0) {
        message.warning("you have not uploaded any attachments");
        return;
      }
      if (fileList.value.length > state.payment.maxFileCount) {
        message.warning(
          `Attachment batch upload can not more than ${state.payment.maxFileCount}.`
        );
        return;
      }
      const isMore5m = isLt5M.value.every((item) => {
        return item;
      });
      if (!isMore5m) {
        message.warning(
          `Attachment can not large than ${state.payment.maxFileSize}M.`
        );
        return;
      }
      fileList.value.forEach((file: FileItem) => {
        formData.append("document", file.originFileObj);
      });
      uploading.value = true;
      API.getSalesUploadFile(payoutRound.value, formData, props.entity).then(
        (data) => {
          fileList.value = [];
          uploading.value = false;
          if (data.code !== "0") {
            Modal.error({
              title: "Tips",
              content: "Upload failed", //res.data.message
            });
          } else {
            Modal.info({
              title: "Tips",
              content: "Successfully Upload！", //res.data.message
            });
            getFileList();
          }
        }
      );
    };

    // const handleUpload = () => {
    //   submitFile(fileList)
    // }
    const deleteAttachment = (record: SalesTableData) => {
      API.getSalesDeleteFile(record.id).then((data) => {
        if (!data) {
          message.success("Successfully Delete!");
          getFileList();
        } else {
          message.error(data);
        }
      });
    };
    const downloadAttachment = (record: SalesTableData) => {
      API.getSalesDownloadFile(record.id).then((data) => {
        const downloadElement = document.createElement("a");
        downloadElement.href = data;
        downloadElement.click();
      });
    };
    return {
      myAttachmentData,
      fileList,
      title,
      // modal,
      close,
      isLt5M,
      submitFile,
      beforeUpload,
      handleRemove,
      uploadComplete,
      attachmentColumns,
      deleteAttachment,
      downloadAttachment,
      fileChange,
      uploading,
    };
  },
});
